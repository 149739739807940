body {
  font-family: Arial, sans-serif;
  background-color: #f1f7eb;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

#splash-screen {
  text-align: center;
  background-color: #eaf3de;
  padding: 30px;
  border-radius: 15px;
}

#splash-screen img {
  width: 150px;
  height: auto;
}

#progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 20px;
  height: 10px;
}

#progress {
  height: 100%;
  width: 100%;
  background-color: #6aaa64;
  border-radius: 5px;
  transition: width 2s;
}

#signup-page {
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

input {
  padding: 8px;
  width: 200px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px;
  width: 100%;
  background-color: #6aaa64;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}